@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

::-webkit-scrollbar {display: none;}


body {
  margin: 0;
  font-family: 'Montserrat';

  scale: 1.0; 
  zoom: 100%; 
  height: 100vh;
}

.regular {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.30000001192092896px;
  text-align: center;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Montserrat';
}

#root { 
  width: 100%; 
  height: 100vh;
  background: linear-gradient(180deg, #F5F5F5 0%, #FFFFFF 100%);
  text-align: center;
  align-items: center;  
  color: #515054;     
  margin-left: auto;
  margin-right: auto;
}
