.buttonAzulClaro {
    gap: 0px;
    width: 90%;
    height: 72px;
    border-radius: 8px 8px 8px 8px;    
    border: 1px solid #727176;
    outline: none;
    padding: 1em;
    box-sizing: border-box;
    font-size: 16px ;    
    background: #00C29E;
    color: #FFFFFF;
    border-color: #00C29E;
    font-family: 'Montserrat', sans-serif;    
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    align-items: center;
}



.whatsLogo {
    width: 24px;
    height: 24px;     
    margin-left: min(5%, 16px);
    fill: #25D366;
    position: absolute;   
    transform: translateY(0%);
}

