.header1 {
    background: linear-gradient(to bottom, #3C3A80 12px, #00C29E 6px);
    height: 18px;
    margin-bottom: 32px;   
    width: 100%;       
}

.footer1 {

    background: #3C3A80;
    height: 8px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    bottom: -1px;    
}

.sobretexto{
    font-weight: 500;
}


.logo {
    width: 90%;
    max-width: 272px;
}

.logo:hover {
    filter: drop-shadow(0 0 2em #646cffaa);
}

.campo {
    position: relative;
    margin-top: min(10%, 48px);
}

.campo input {     
    font-family: 'Montserrat';
    width: 90%;
    gap: 0px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #727176;    
    background-color: white;
    color: #888;
    outline: none;
    padding: 1em;
    box-sizing: border-box;
    font-size: 16px;   
}

.campo input:focus {
    box-shadow: 0 0 3px #3C3A80;
    color: #3C3A80; 
    border-color: #3C3A80;
}

.campo label {
    position: absolute;
    top: -10px;
    left: calc(0, 0); 
    border-radius: 100px;
    background-color: white;  
    font-family: 'Montserrat';  
    font-weight: 500;
    color: #3C3A80; 
}

.campo label.error, .campo input.error {
    color: #FE4B5C;
    border-color: #FE4B5C;
}

.campo input.error:focus {
    box-shadow: 0 0 3px #FE4B5C;
}

.error-cpf, .error-senha{
    display: none;
    position: absolute;
    color: #FE4B5C;
    font-size: 12px;
    top: 100%;
    left: min(12%, 80px);

}


.recuperarSenha {
    font-weight: 600;
    line-height: 24px;
    color: #3C3A80;     
    width: 90%;
    margin:auto;
    margin-top: 16px;
    margin-bottom: 40px;    
    text-decoration: none;
}

.recuperarSenha2 a {
    line-height: 18px;
    color:  #727176;
    width: 100%;
    font-size: 12px;
    line-height: 18px;
    margin-top: 16px;
    font-weight: 500;
    text-decoration: none;
}

.mostrarsenha {
    position: absolute;
    right: min(10%, 90px);   
    top: 55%;
    transform: translateY(-50%);
}


