

.popupCust {
    width: 90%;    
    height: auto;       
    border: 2px; 
    border-radius: 16px;    
    background-color: white;
    margin: auto;
    padding-top: 32px;
    padding-bottom: 32px;
    box-shadow: 0px 2px 8px 0px #00000040;   
}

.popup-content {   
    background: transparent;
    border: none;
    text-align: center;
    width: 100%;    
    margin: auto;  
  }

.alerta {
    font-family: 'Montserrat', sans-serif;      
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    text-align: left;
    width: 90%;
    margin:auto;   
    margin-bottom: 16px;    
}

.sobalerta {    
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    letter-spacing: 0.3px;
    text-align: left;
    width: 90%;    
    margin:auto;   
    color: #515054; 
    margin-bottom: 32px;
}

.botoes{    
    margin:auto;    
}
