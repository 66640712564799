.faq-container {
    text-align: left;
    width: 336px;
    padding: 18px;
    background: var(--Neutral-0, #FFFFFF);
    border-radius: 8px;
    margin: auto;
    margin-bottom: 16px;
    font-size: 16px;
    color: #000000;
  }
  
  .faq-aberto {
    width: 336px;
    height: 260px;
    padding: 16px;
    color: #000000;
    gap: 4px;
    border-radius: 8px;    
  }
  
  .botao-seta {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .botao-seta img {

    width: 12px;
    height: 7.41px;
  }
  