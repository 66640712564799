.headerFinal{
    background: linear-gradient(to bottom, #3C3A80 12px, #00C29E 6px);
    height: 18px;
    top: 0;
    margin-bottom: 32px;
    width: 100%;
}



.footerFinal{    
    background: #3C3A80;
    height: 8px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    bottom: -1px;    
}

.textBolder{
    width: 90%; 
    margin: auto;
    margin-top: min(10%, 32px);
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    letter-spacing: 0.30px;
    text-align: center;
    color: #3C3A80;
    margin-bottom: min(10%, 24px);
    }

.textComum{    
    width: 90%; 
    margin: auto;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    letter-spacing: 0.30px;
    text-align: center;
    margin-bottom: min(10%, 40px);
    color: #515054;
    }

.imagem1{    
    margin: auto;
    width: min(90%, 312px); 
    border-radius: 8px 8px 8px 8px;
    margin-bottom: min(10%, 32px);
}

.imagem2{
    margin: auto;
    width: min(90%, 312px);
    border-radius: 8px 8px 8px 8px;
    margin-bottom: min(10%, 60px);
}