.headerLoad {
    background: #00C29E;
    height: 6px;
    top: 0;
    left: 0;
    width: 100%;
}

.footerLoad {
    background: #3C3A80;
    height: 8px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    bottom: -1px;    
}

.mensagens{ 
    font-family: 'Montserrat', sans-serif;
    font-size: 28px;
    font-weight: 400;
    line-height: 36.4px;    
    width: 80%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: min(5%, 50px);
}

.alertaLoading { 
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    width: 80%;
    margin-top: 32px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.sobalertaLoading {    
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    letter-spacing: 0.3px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.fixo {
    font-family: 'Montserrat', sans-serif;
    position: fixed;
    width: 100%;
    bottom: min(5%, 50px);
  }

.loading-bar-container {
    top: 100%;
    width: 100%;
    height: 10px; 
    background-color: #f0f0f0;

}

.loading-bar {
    top: 0;
    height: 100%;
    margin-bottom: 32px;   
    background: linear-gradient(to right, #3C3A80, #00C29E); 
    transition: width 0.3s ease-in-out;
}
