.headerTest {
    background: linear-gradient(to bottom, #3C3A80 12px, #00C29E 6px);
    height: 18px; 
    width: 100%;       
}

.textAviso{

    margin: auto;
    margin-bottom: 50px;    
}