.buttonText{
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    color: #3C3A80;
    background-color: white;
    border: none;
    margin-top: 32px;
}

