.headerResult {
    background: linear-gradient(to bottom, #3C3A80 12px, #00C29E 6px);
    height: 18px;
    margin-bottom: 32px;
    width: 100%;
}

.footerResult {
    background: #3C3A80;
    height: 8px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: -1px;
}


.verificado{
    margin-top: min(30%, 50px);   
    width: 120px;
    height: 120px;
    top: 10px;
    left: 10px;
    gap: 0px; 
}

.text1{
    font-family: 'Montserrat', sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    text-align: center;
    color: #3C3A80;
    margin-top: 40px;
    margin-bottom: 24px;
    }

.text2{    
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.4px;
    letter-spacing: 0.30000001192092896px;
    text-align: center;    

    
    margin-bottom: 40px;
}
