.buttonAzul {
    gap: 0px;
    width: 90%;
    border-radius: 8px 8px 8px 8px;    
    border: 1px solid #727176;
    outline: none;
    padding: 1em;
    box-sizing: border-box;  
    background-color: #3C3A80;
    color: white;
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}